<template>
  <v-app id="app">
    <DefaultBar/>
    <Drawer/>
    <Views/>
  </v-app>
</template>

<script>
export default {
  name: "Index",
  components: {
    DefaultBar: () => import('./AppBar.vue'),
    Drawer: () => import('./Drawer.vue'),
    Views: () => import('./Views.vue')
  }
}
</script>

<style scoped>

</style>